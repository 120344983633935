<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <v-row>
            <v-col>
                <v-container class="my-5 adminContainer">
                    <v-row>
                        <v-col cols="0" md="2">
                            <admin-acc-menu></admin-acc-menu>
                        </v-col>
                        <v-col cols="12" md="10">
                            <v-row>

                                <v-col cols="12" class="sub-title-3 mt-2 pb-0">
                                    접속경로별 접속통계
                                </v-col>
                                <v-col cols="12" style="display: flex;">
                                    <select v-model="year" @change="changeYear($event)" class="select-d">
                                        <option v-for="(n, i) in yearList" :value="n">{{n}}년</option>
                                    </select>
                                    <select v-model="month" @change="changeMonth($event)" class="select-d ml-2">
                                        <option v-for="(n, i) in 12" :value="n">{{n}}월</option>
                                    </select>
                                    <select v-model="day" @change="changeDay($event)" class="select-d ml-2">
                                        <option v-for="(n, i) in 31" :value="n">{{n}}일</option>
                                    </select>
                                </v-col>
                                <v-col class="overflow-x-auto">
                                    <table class="adminListTable">
                                        <colgroup>
                                            <!--<col style="width:16%"/>-->
                                            <col style="width:8%"/>
                                            <col style="width:15%"/>
                                            <col style="width:20%"/>
                                            <col style="width:12%"/>
                                            <col style="width:45%"/>

                                        </colgroup>
                                        <tr>
                                            <th>번호</th>
                                            <th>접속IP</th>
                                            <th>검색어</th>
                                            <th>검색엔진</th>
                                            <th>접속경로</th>

                                        </tr>
                                        <tr v-for="(item, i) in accList"
                                            :key=i
                                        >
                                            <td>
                                                {{i+1}}
                                            </td>
                                            <td>
                                                {{item.accIp}}
                                            </td>
                                            <td>
                                                {{item.accWord}}
                                            </td>
                                            <td>
                                                {{item.accEng}}
                                            </td>
                                            <td>
                                                <div style="white-space : nowrap; overflow : hidden; text-overflow: ellipsis; width:400px;">
                                                    {{item.accRef}}
                                                </div>
                                            </td>

                                        </tr>
                                    </table>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>

    </div>
</template>

<script>

    import AdminAccMenu from '../../components/AdminAccMenu'

    export default {
        name: 'AdminAccPath',
        components: {AdminAccMenu},
        data: () => ({
            page: 1,
            pageCount: 0,
            itemsPerPage: 30,

            accList: [],
            yearList: [],
            year: "",
            month: "",
            day: "",
            lstDay: "0",


        }),
        created: function () {
            // 맨위로
            window.scrollTo(0, 0);

            let now = new Date();	// 현재 날짜 및 시간
            let year = now.getFullYear();	// 연도
            let month = now.getMonth();
            let day = now.getDate();
            this.year = String(year);
            this.month = String(month + 1);
            this.day = day;

            this.lstDay = new Date(year, month + 1, 0).getDate();
            for (year; year > 2020; year--) {
                this.yearList.push(year);
            }

            this.getList();

        },
        methods: {
            getList() {

                let formdata = {
                    year: this.year,
                    month: this.month,
                    day: this.day
                };

                return this.$store.dispatch("acc/getAccList", formdata)
                    .then((resp) => {
                        setTimeout(() => {

                            this.accList = resp.message;
                            //this.getCnt()

                        }, 300)
                    })
                    .catch((err) => {
                    })
            },
            menuClick(route, roomGubun) {
                location.href = route;
            },
            changeYear(event) {
                this.year = event.target.value;
                this.getList();

            },
            changeMonth(event) {
                this.month = event.target.value;
                this.getList();

            },
            changeDay(event) {
                this.day = event.target.value;
                this.getList();

            },

        },
    }
</script>

<style>

</style>


